







































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { Iban, ProviderId } from '@/models/User'
import {addIban, deleteIban, downloadAccountStatement, updateIban} from '@/clients/cpinblocks'
import { isIban } from '@/plugins/iban'
import { ValidationRules } from '@/types'
import moment from 'moment-timezone'
import DateInput from '@/components/DateInput.vue'
import { FamilySituation, LegalStatus } from '@/models/KYInformation'
import VerificationFactorFileInput from '@/components/VerificationFactorFileInput.vue'
import { KYType, VerificationFactor } from '@/models/KYState'

@Component({
  computed: {
    KYType () {
      return KYType
    },
    VerificationFactor () {
      return VerificationFactor
    },
  },
  components: {
    VerificationFactorFileInput,
    DateInput,
  },
})
export default class MyAccount extends Vue {
  private IBAN_MASK = 'AAXX XXXX XXXX XXXX XXXX XXXX XXXX XXXX XX'

  private ibanList: Iban[] = []
  private isValid = false
  private addIbanDialog = false
  downloadingStatements: string[] = []
  private loading = true
  private ibanLoading = false
  private newIban: Iban = new Iban()
  private newIbanOwnerIsBeneficiary = false
  private newMaskedIbanValue = ''
  private rawIbanValue?: string
  private sendPoa = false
  private validPoa = false
  private thirdPartyHosted = false
  private deleteIban?: Iban
  private deleteIbanDialog = false
  private modifyIbanDialog = false
  private modifyIban: Iban | null = null
  private isValidModifyIban = false

  private months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  private get incompleteIbans (): boolean {
    return this.ibanList.some(iban => {
      return iban.accountHolderFullName === undefined
    })
  }

  private get userEmail () {
    return this.$store.state.owner?.userIds?.filter((i: ProviderId) => i.provider && i.provider === 'email')[0].id
  }

  private get isEmailExist () {
    return this.$store.state.owner?.userIds.some((i: ProviderId) => i.provider === 'email')
  }

  private get statementsDate () {
    const result: any[] = []
    const d = new Date(this.$store.state.owner.createdAt)
    const now = new Date();
    for (let y = d.getFullYear(); y <= now.getFullYear(); y++) {
      let months = []
      let startMonth = y === d.getFullYear() ? d.getMonth() + 1 : 1
      let endMonth = y === now.getFullYear() ? now.getMonth() : 12
      for (let m = startMonth; m <= endMonth; m++) {
        months.push(m)
      }
      result.push({year: y, months: months})
    }
    return result
  }

  private get rules (): ValidationRules {
    return {
      required: [
        (v?: string) => !!v || this.$t('rule.requiredField'),
      ],
      iban: [
        (iban?: string | null) => (!!iban && isIban(iban.split(' ').join(''))) || 'Invalid IBAN format',
      ],
      asciiWordsOneSpace: [
        (v: string) => (/^\b(?!.*?\s{2})[A-Za-z ]{1,40}\b$/.test(v)) || 'Only letter A-Z accepted',
      ],
    }
  }

  private get getStatus (): string {
    const status = this.$store.state.owner?.institutional?.companyIdentity?.status
    return status !== undefined ? (status + ((status !== LegalStatus.OTHER) ? ` - ${this.$t('ky.company_identification.status.' + status.toString().toLowerCase())}` : '')) : ''
  }

  showAccountHolderDialog (iban: Iban): void {
    this.modifyIban = Object.assign({}, iban)
    this.modifyIban.accountHolderFullName = ''
    this.modifyIbanDialog = true
  }

  async copy (text: string): Promise<void> {
    await navigator.clipboard.writeText(text)
  }

  async addIbanFormAction (): Promise<void> {
    this.ibanLoading = true
    await addIban(this.newIban, this.newIbanOwnerIsBeneficiary, this.$store.state.jwt)
    await this.resetForm()
    this.newIban = new Iban()
    this.newMaskedIbanValue = ''
    this.newIbanOwnerIsBeneficiary = false
    this.ibanLoading = false
    this.addIbanDialog = false
  }

  async deleteIbanFormAction (): Promise<void> {
    if (this.deleteIban !== undefined) {
      this.ibanLoading = true
      await deleteIban(this.deleteIban.value, this.$store.state.jwt)
      await this.resetForm()
      this.ibanLoading = false
      this.deleteIbanDialog = false
    }
  }

  showDeleteIbanDialog (iban: Iban): void {
    this.deleteIban = iban
    this.deleteIbanDialog = true
  }

  async resetForm (): Promise<void> {
    this.ibanList = this.$store.state.owner?.ibans || []
  }

  async mounted (): Promise<void> {
    await this.resetForm()
    if (this.$store.state.owner !== null) this.loading = false
  }

  getCountry (code?: string): string {
    return code !== undefined ? (this.$t('countries.' + code.toLowerCase()).toString()) : ''
  }

  private async updateIbanFromAction (): Promise<void> {
    if (this.modifyIban !== null) {
      this.ibanLoading = true
      await updateIban(this.modifyIban, this.$store.state.jwt)
      this.modifyIban = null
      await this.resetForm()
      this.ibanLoading = false
      this.modifyIbanDialog = false
    }
  }

  private updateSendPoa (send: boolean): void {
    this.sendPoa = send
  }

  private updateValidPoa (valid: boolean): void {
    this.validPoa = valid
  }

  private getFamilySituation (familySituation: FamilySituation): string {
    return familySituation ? this.$t('ky.identity_of_individual.family_situation.' + familySituation.toLowerCase()).toString() : ''
  }

  private goToKy (): void {
    window.location.href = window.location.origin + '/ky'
  }

  async onClickGetAccountStatement (year: number, month: number | null) {
    let key = month === null ? '' + year : month + '/' + year
    this.downloadingStatements.push(key)
    await downloadAccountStatement(this.$store.state.jwt, year, month)
    let index = this.downloadingStatements.indexOf(key)
    const newState = []
    for (let i=0; i<this.downloadingStatements.length; i++) {
      if (i !== index) {
        newState.push(this.downloadingStatements[i])
      }
    }
    this.downloadingStatements = newState
  }

  private formatDate (date: Date): string {
    const d = moment(date)
        .tz(moment.tz.guess())
    if (d.isAfter('2022-03-24')) {
      return d.format('YYYY-MM-DD')
    } else {
      return '2022-03-24'
    }
  }
}
